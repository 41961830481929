@media (max-width: 767px) {
  .react-datepicker {
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: -275px;
    left: 18px;
    position: relative;
  }

  .react-datepicker__month-container {
    width: 100vw;
    background: #fff;
    border-radius: 16px 16px 0 0;
    padding: 16px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0;
  }
  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 44px;
    line-height: 44px;
  }
}

@media (min-width: 768px) {
  .react-datepicker__month-container {
    width: 330px;
    background: #fff;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    text-align: center;
    height: 44px;
    line-height: 44px;
  }
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__day {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 8px;
  background: #2297ff;
  color: #fff;
}

.react-datepicker__day--selected {
  border-radius: 8px;
  background: #2297ff;
  color: #fff;
  outline: none;
}

.react-datepicker__day--disabled {
  color: #999999;
}

.react-datepicker__day--outside-month {
  color: #fff;
}

.react-datepicker__day--outside-month:hover {
  background-color: #fff;
  color: #fff;
  cursor: default;
  > span {
    color: #fff;
  }
}

.react-datepicker__day--disabled:hover {
  background-color: #fff;
  cursor: default;
  > span {
    color: #fff;
  }
}

.react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 16px;
}
